var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('p',{staticClass:"box-text"},[_vm._v(" "+_vm._s(_vm.$t("crt-quote.step3.info-text")))]),_c('validation-observer',{ref:"step3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var touched = ref.touched;
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('form',[_c('div',{staticClass:"d-flex w-100 mt-4"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Product Title","rules":{ itemName: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mr-3",class:{
                hasError:
                  (
                      (
                          !_vm.getValidationState(validationContext) 
                          && 
                          validationContext.errors[0]
                      )
                      ||
                      _vm.nexists === true
                  )
              }},[_c('label',{attrs:{"for":"productName"}},[_vm._v(_vm._s(_vm.$t("crt-quote.step3.label-1"))+" "),_c('small',{staticClass:"ml-2",staticStyle:{"color":"c5c5c5"}},[_vm._v(" "+_vm._s(_vm.$t("crt-quote.step3.label-desc")))])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.name),expression:"item.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"spellcheck":"true","id":"productName","placeholder":_vm.$t('crt-quote.step3.ph1'),"type":"text"},domProps:{"value":(_vm.item.name)},on:{"keyup":function($event){return _vm.checkName()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(validationContext.errors[0])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):(_vm.nexists)?_c('div',{staticClass:"text-danger"},[_vm._v(" Quote title already exists ")]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":"Product Id","rules":{ required: true, min: 3, max: 40 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group ml-3",class:{
                  hasError:
                    !_vm.getValidationState(validationContext) &&
                    validationContext.errors[0],
                }},[_c('label',{attrs:{"for":"productID"}},[_vm._v(_vm._s(_vm.$t("crt-quote.step3.label-2")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.productId),expression:"item.productId",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"spellcheck":"true","id":"productID","placeholder":_vm.$t('crt-quote.step3.ph2'),"type":"text"},domProps:{"value":(_vm.item.productId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "productId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(validationContext.errors[0])?_c('div',{staticClass:"text-danger "},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":"Product Description","rules":{ itemDescription: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mt-4",class:{
              hasError:
                (
                    !_vm.getValidationState(validationContext) && validationContext.errors[0]
                )
            }},[_c('label',{attrs:{"for":"productDesc"}},[_vm._v(_vm._s(_vm.$t("crt-quote.step3.label-3")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.item.desc),expression:"item.desc",modifiers:{"trim":true}}],staticClass:"form-control",staticStyle:{"min-height":"120px"},attrs:{"spellcheck":"true","placeholder":_vm.$t('crt-quote.step3.ph3'),"id":"productDesc","cols":"30","rows":"4"},domProps:{"value":(_vm.item.desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "desc", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"counter"},[(validationContext.errors[0])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e(),_c('div',{staticClass:"number-back-counter"},[_vm._v(" "+_vm._s(_vm.counter)+" / 1200 ")])])])]}}],null,true)})],1)]}}])}),_c('div',{staticClass:"box-info "},[_c('div',{staticClass:"box-info-item mb-0"},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"icon":['fac', 'lamb']}})],1),_c('span',[_vm._v(_vm._s(_vm.$t("crt-quote.step3.info-p")))])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }