<template>
  <div>
    <p class="box-text">

      {{$t("crt-quote.step4.info-text")}}
      <!-- Add images that best represents <thead></thead> products you are selling. -->
    </p>
    <div class="dropzone-content mb-34">
      <vue-dropzone
        @vdropzone-files-added="fileUpload"
        @vdropzone-thumbnail="getThumbnail"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            {{$t("crt-quote.step4.drop-img")}}
          </h3>
          <small class="text-muted d-block">{{$t("crt-quote.step4.or")}}</small>
          <button @click.prevent class="btn btn-primary">
            {{$t("buttons.addimage")}}
          </button>
          <p>JPG,PNG 5MB max.</p>
        </div>
      </vue-dropzone>
      <div class="img-preview-content mt-3 d-flex justify-content-between"
        
      >
        <div v-for="(b, i) in 5" :key="i" class="item">
          <div
            @click.prevent="deleteThumbnail(i)"
            v-if="productImages[i]"
            class="clearBtn"
          >
            X
          </div>
          <img :src="!productImages[i] ? noImage : productImages[i].src" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseImage from "@/assets/img/product-add-no-image.png";
import _ from "lodash"
export default {
  props: {
    itemImages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    productImagesData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      productImages: [],
      item: {
        images: [],
      },
      noImage: baseImage,
      dropzoneOptions: {
        createImageThumbnails: true,
        addRemoveLinks: true,
        autoProcessQueue: false,
        url: "https://httpbin.org/post",
        thumbnailWidth: null,
        thumbnailHeight: null,
        acceptedFiles: "image/*",
        maxFilesize: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  watch: {
    productImages(val) {
      console.log(val);
    },
  },
  methods: {
    fileUpload(file) {
      if (file[0].size > 5000000) {
        this.$bvToast.toast(this.$t("Toaster.img-excced"), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
        return false;
      }
      if (this.productImages.length === 5) {
        this.$bvToast.toast(this.$t("Toaster.img-add-up"), {
          title:this.$t("Toaster.error-title"),
         autoHideDelay: 1350,
        });
        return false;
      }
    },
    getThumbnail(file, dataUrl, $event) {
      if (file.size > 5000000) {
        return;
      }
      if (this.productImages.length === 5) {
        return;
      }
      this.item.images.push(file);
      this.productImages.push({
        src: dataUrl,
      });
      this.$emit("productImage", {
        productImages: this.productImages,
        itemImages: this.item.images,
      });
    },
    deleteThumbnail(index) {
      this.productImages.splice(index, 1);
      this.productImages = [...this.productImages];
      this.item.images.splice(index, 1);
      this.item.images = [...this.item.images];
      this.$emit("productImage", {
        productImages: this.productImages,
        itemImages: this.item.images,
      });
    },
  },
  created() {
    this.item.images = _.cloneDeep(this.itemImages);
    this.productImages = _.cloneDeep(this.productImagesData);
  },
};
</script>
