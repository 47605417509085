<template>
  <div>
    <p class="box-text">
       {{$t("crt-request.step4.info-text")}}
    </p>
    <loading :loading="loading" />
    <div class="row " v-if="!loading" >
      <div class="col-12">
        <div class="form-group">
          <label for="">{{$t("crt-request.step4.label-1")}}</label>
          <multi-select
            track-by="name"
            label="name"
            :placeholder="$t('crt-request.step4.ph1')"
            :clearOnSelect="false"
            :closeOnSelect="true"
            :allowEmpty="false"
            :showLabels="false"
            v-model="form.selectUnit"
            :options="units"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title text-capitalize">{{
                  props.option.name
                }}</span>
              </div>
            </template>
          </multi-select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="">{{$t("crt-request.step4.label-2")}}</label>
          <input spellcheck="true"
            v-model="form.capacity"
            :placeholder="$t('crt-request.step4.ph2')"
            type="number"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="box-info mt-4">
          <div class="box-info-item mb-0">
            <div class="icon">
              <v-icon :icon="['fac', 'lamb']" />
            </div>
            <span
              >{{$t("crt-request.step4.info-p")}}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/global/loading/index";

export default {
  components: {
    loading,
  },
  props: {
    infoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      form: {
        capacity: null,
        selectUnit: null,
      },
      units: [],
    };
  },
  watch: {
    form: {
      handler(v) {
        this.$emit("updateVal", this.form);
        if (!v.capacity || !v.selectUnit) {
          return this.$emit("disable", true);
        }
        return this.$emit("disable", false);
      },
      deep: true,
    },
  },
  methods: {
    async getUnits() {
      try {
        let response = await this.$store.dispatch("inquiries/getUnits", {
          vm: this,
        });
        this.units = response;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    this.form = this.infoData;
    await this.getUnits();
    this.loading = false;
  },
};
</script>
