<template>
  <div>
    <div>
      <p class="box-text"> {{$t("crt-quote.step3.info-text")}}</p>
      <validation-observer ref="step3" v-slot="{ touched, invalid, changed }">
        <form>
          <div class="d-flex w-100 mt-4">
            <validation-provider
              class="w-100"
              name="Product Title"
              :rules="{ itemName: true }"
              v-slot="validationContext"
            >
              <div
                class="form-group mr-3"
                :class="{
                  hasError:
                    (
                        (
                            !getValidationState(validationContext) 
                            && 
                            validationContext.errors[0]
                        )
                        ||
                        nexists === true
                    )
                }"
              >
                <label for="productName"
                  >{{$t("crt-quote.step3.label-1")}}
                  <small style="color: c5c5c5" class="ml-2">
                    {{$t("crt-quote.step3.label-desc")}}</small
                  >
                </label>
                <input spellcheck="true"
                  v-model.trim="item.name"
                  @keyup="checkName()"
                  id="productName"
                  :placeholder="$t('crt-quote.step3.ph1')"
                  type="text"
                  class="form-control"
                />
                <div v-if="validationContext.errors[0]" class="text-danger">
                  {{ validationContext.errors[0] }}
                </div>
                <div v-else-if="nexists" class="text-danger">
                  Quote title already exists
                </div>
              </div>
            </validation-provider>
            <div class="w-100">
              <validation-provider
                name="Product Id"
                :rules="{ required: true, min: 3, max: 40 }"
                v-slot="validationContext"
              >
                <div
                  class="form-group ml-3"
                  :class="{
                    hasError:
                      !getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }"
                >
                  <label for="productID">{{$t("crt-quote.step3.label-2")}}</label>
                  <input spellcheck="true"
                    id="productID"
                    :placeholder="$t('crt-quote.step3.ph2')"
                    type="text"
                    class="form-control"
                    v-model.trim="item.productId"
                  />
                  <div v-if="validationContext.errors[0]" class="text-danger ">
                    {{ validationContext.errors[0] }}
                  </div>
                </div>
              </validation-provider>
            </div>
          </div>
          <validation-provider
            name="Product Description"
            :rules="{ itemDescription: true }"
            v-slot="validationContext"
          >
            <div
              class="form-group mt-4"
              :class="{
                hasError:
                  (
                      !getValidationState(validationContext) && validationContext.errors[0]
                  )
              }"
            >
              <label for="productDesc">{{$t("crt-quote.step3.label-3")}}</label>
              <textarea spellcheck="true" 
                :placeholder="$t('crt-quote.step3.ph3')"
                id="productDesc"
                cols="30"
                rows="4"
                v-model.trim="item.desc"
                class="form-control"
                style="min-height:120px"
              ></textarea>
              <div class="counter">
              <div v-if="validationContext.errors[0]" class="text-danger">
                {{ validationContext.errors[0] }}
              </div>
              <div class="number-back-counter">
                  {{ counter }} / 1200
              </div>
              </div>
            </div>
          </validation-provider>
        </form>
      </validation-observer>
      <div class="box-info ">
        <div class="box-info-item mb-0">
          <div class="icon">
            <v-icon :icon="['fac', 'lamb']" />
          </div>
          <span
            >{{$t("crt-quote.step3.info-p")}}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/global/loading/index";
import CHECK_NAME from '@/graphql/me/checkProduct.graphql';
import _ from 'lodash';
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    loading,
  },
  data() {
    return {
      industires: [],
      selectedIndustry: null,
      loading: true,
      nexists: false,
      item: {},
    };
  },
  computed: {
    counter() {
        return _.size(this.item.desc);
    }
  },
  watch: {
    item: {
      handler: async function (v) {
        let result = await this.$refs.step3.validate();
        if (!result) {
            this.$emit('validateForm', {});
            return;
        }

        if (this.nexists === true) {
            this.$emit('validateForm', {});
            return;
        }

        this.$emit('validateForm', this.item);
      },
      deep: true,
    },
  },
  methods: {
      async checkName() {
          let selector = await this.$apollo.query(CHECK_NAME, { name: this.item.name || ' ok ' });
          this.nexists = selector('**.check');
      }
  },
  created() {
    this.item = this.detailData;
    this.$emit("validateForm", this.item);
    this.$nextTick(() => this.$refs.step3.reset());
  },
};
</script>
