<template>
  <div>
    <loading :loading="loading" />
    <div v-if="!loading" class="">
      <p class="box-text">
        {{$t("crt-quote.step2.info-text")}}
      </p>
      <div class="form-group my-5">
        <label for="#"> {{$t("crt-quote.step2.label")}}</label>
        <multi-select
          track-by="name"
          label="name"
          @select="selectIndustry"
          :placeholder="$t('crt-quote.step2.ph')"
          :clearOnSelect="false"
          :closeOnSelect="true"
          :allowEmpty="false"
          :showLabels="false"
          v-model="selectedIndustry"
          :options="industires"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
        </multi-select>
      </div>
      <div class="box-info">
        <div class="box-info-item mb-0">
          <div class="icon">
            <v-icon :icon="['fac', 'lamb']" />
          </div>
          <span
            >{{$t("crt-quote.step2.info-p1")}}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/global/loading/index";
export default {
  props: {
    selectIndustryData: {
      type: Number,
      default: 0,
    },
  },
  components: {
    loading,
  },
  data() {
    return {
      industires: [],
      selectedIndustry: null,
      loading: true,
    };
  },
  methods: {
    selectIndustry(selectedOption) {
      this.$emit("selectIndustry", selectedOption);
    },
    async fetchIndustry() {
      try {
        let par = {
          type: "PRODUCT_AND_INQUIRY",
        };
        let response = await this.$store.dispatch("inquiries/getIndustries", {
          vm: this,
          payload: par,
        });
        this.industires = response;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.fetchIndustry();
    if (this.selectIndustryData !== 0) {
      let handler = this.industires.find(
        (item) => item.id === this.selectIndustryData
      );
      if (handler) {
        return (this.selectedIndustry = handler);
      }
      this.selectedIndustry = null;
    }
  },
};
</script>
