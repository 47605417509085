<template>
  <div>
    <b-modal
      @show="getInquiryCount"
      @hidden="resetModal"
      :hide-footer="loading || alert.status"
      centered
      id="itemAddInqury"
      size="lg"
      title="BootstrapVue"
      modal-class="zoom"
      >
      <template #modal-header="{ close }">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <h5>{{ activeStep.title }}</h5>
          <a @click.prevent="close()" href="#">
            <v-icon size="2x" :icon="['fac', 'close']"></v-icon>
          </a>
        </div>
      </template>
      <loading :loading="loading"></loading>
      <div v-if="alert.status">
        <h3 class="text-center">{{ alert.message }}</h3>
        <div
          v-if="alert.type === 'system'"
          class="d-flex justify-content-center"
        >
          <a
            class="btn btn-primary btn-sm mt-3"
            href="mailto:support@connectter.com"
            
            >{{$t("buttons.contact-us")}}</a
          >
        </div>
        <div
          v-if="alert.type === 'limit'"
          class="d-flex justify-content-center"
        >
          <a
            class="btn btn-primary btn-sm mt-3"
            href="/app/profile/settings/plan"

            >{{$t("buttons.click-cp")}}</a
          >
        </div>
      </div>
      <div v-if="!loading && !alert.status" class="">
       <div v-for="(step, index) in undisabledSteps" :key="index">
            <component 
                v-if="index === stepIndex"
                :is="step.component"
                :selectIndustryData="item.selectIndustry"
                @selectIndustry="selectedIndustry"
                :detailData="item.detail"
                @validateForm="val => item.detail = val"
                :infoData="item.info"
                @updateVal="val => item.info = val"
                @disable="val => disabledStep = val"
            />
       </div>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <div class="box-proggress">
          <a
            @click.prevent="stepIndex < $_.size(undisabledSteps) -1 ? changeStep(stepIndex + 1): finish()"
            :class="[activeStep.class, !activeStep.check() ? 'disabled': '']"
            href="#"
          >
            {{ activeStep.button }}
          </a>
          
        </div>
        <div class="d-flex justify-content-between mt-2 w-100">
          <span class="step-info"> {{ stepIndex + 1 }} of {{ $_.size(undisabledSteps) }} </span>
          <b-form-checkbox
                v-if="stepIndex <= 0 && preDoNotShowAgain == false"
                class="float-right"
                size="sm"
                v-model="doNotShowAgain"
                :value="true"
                :unchecked-value="false"
            >
                {{$t("General.do-not-show")}}
            </b-form-checkbox>
          <a @click="goBack(stepIndex - 1)" v-if="stepIndex > 0" href="#"> Go Back </a>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import loading from "@/components/global/loading/index";
import step1 from "@/components/app/matching/component/addItemSteps/inqury/step1";
import step2 from "@/components/app/matching/component/addItemSteps/inqury/step2";
import step3 from "@/components/app/matching/component/addItemSteps/inqury/step3";
import step4 from "@/components/app/matching/component/addItemSteps/inqury/step4";
import getInquryCount from "@/graphql/me/getInquriesCount.graphql";
import GET_DO_NOT_SHOW_AGAIN from "@/graphql/me/doNotShowAgain.graphql";
import SET_DO_NOT_SHOW_AGAIN from "@/graphql/me/setDoNotShowAgain.graphql";

export default {
  components: {
    loading,
    step1,
    step2,
    step3,
    step4,
  },
  data() {
    return {
      alert: {
        status: false,
        type: null,
        message: null,
      },
      doNotShowAgain: false,
      preDoNotShowAgain: false,
      loading: false,
      disabledStep: true,
      stepIndex: 0,
      steps: [
        {
          disabled: false,
          component: "step1",
          class: "w-25",
          title: this.$t('crt-request.step1.title'),
          button: "Get Started",
          check: () => true
        },
        {
          disabled: false,
          component: "step2",
          class: "w-50",
          title: this.$t('crt-request.step2.title'),
          button: "Next",
          check: () => !_.isNil(this.item.selectIndustry)
        },
        {
          disabled: false,
          component: "step3",
          class: "w-75",
          title: this.$t('crt-request.step3.title'),
          button: "Next",
          check: () => _.has(this.item.detail, 'desc')
        },
        {
          disabled: false,
          component: "step4",
          class: "w-100",
          title: this.$t('crt-request.step4.title'),
          button: "Finish",
          check: () => (
              _.isNil(this.item.info.capacity) === false 
              && 
              _.isNil(this.item.info.selectUnit) === false
          )
        }
      ],
      item: {
        selectIndustry: null,
        detail: {},
        info: {
          selectUnit: null,
          capacity: null,
        },
        images: {
          itemImages: [],
          productImages: [],
        },
      },
    };
  },
  computed: {
      undisabledSteps() {
          return _.filter(this.steps, x => x.disabled === false);
      },
      activeStep() {
          return this.undisabledSteps[this.stepIndex];
      }
  },
  methods: {
    ...mapActions({ getIndustries: "inquires/getIndustries" }),
    async getInquiryCount() {
      try {
        this.alert.status = false;
        this.loading = true;
        let data = await this.$apollo.query(getInquryCount, { filters: {} });
        let count = data("**.count");
        let inqury_limit = this.GET_USER_INFO.group.permissions.inquiry_limit;
        if (count >= inqury_limit) {
          this.alert.status = true;
          this.alert.type = "limit";
          this.alert.message = this.$t('toaster.error.req-limit-reach');
        }

        let selector = await this.$apollo.query(GET_DO_NOT_SHOW_AGAIN, { type: 'INQUIRY' });
        this.steps[0].disabled = this.preDoNotShowAgain = this.doNotShowAgain = selector('**.doNotShowAgain');
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        this.alert.status = true;
        this.alert.type = "system";
        this.alert.message = msg;
      } finally {
        this.loading = false;
      }
    },
    selectedIndustry(par) {
      this.item.selectIndustry = par.id;
    },
    uploadProduct(val) {
      this.item.images.itemImages = val.itemImages;
      this.item.images.productImages = val.productImages;
    },
    goBack(step) {
      this.stepIndex = step;
    },
    resetModal() {
      this.stepIndex = 0;
      this.alert = {
        status: false,
        type: null,
        message: null,
      };
      this.item = {
        selectIndustry: null,
        detail: {},
        info: {},
        images: {
          itemImages: [],
          productImages: [],
        },
      };
    },
    async finish() {
      try {
        this.loading = true;
        let payload = {
          industry_id: parseInt(this.item.selectIndustry),
          name: this.item.detail.name,
          unit_id: parseInt(this.item.info.selectUnit.id),
          demand: parseInt(this.item.info.capacity),
          description: this.item.detail.desc,
          active: true,
        };

        await this.$store.dispatch("inquiries/addInqury", { vm: this, payload });
        if (this.doNotShowAgain === true) {
            await this.$apollo.mutate(SET_DO_NOT_SHOW_AGAIN, { type: 'INQUIRY' });
        }

        this.$store.commit('INC_ITEM_KEY');
        this.$bvModal.hide("itemAddInqury");
        this.$router.push('/app/my/requests');
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        return this.$bvToast.toast(msg, {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
    changeStep(step) {
      this.stepIndex = step;
    },
  },
  created() {},
};
</script>