<template>
  <div>
    <div>
      <p class="box-text">{{$t("crt-request.step3.info-text")}}</p>
      <validation-observer ref="step3" v-slot="{ touched, invalid, changed }">
        <form action="#">
          <div class="d-flex w-100 mt-4">
            <validation-provider
              class="w-100"
              name="Product Name"
              :rules="{ itemName: true }"
              v-slot="validationContext"
            >
              <div
                class="form-group"
                :class="{
                  hasError:
                    (
                        (
                            !getValidationState(validationContext) 
                            && 
                            validationContext.errors[0]
                        )
                        ||
                        nexists === true
                    )
                }"
              >
                <label for="productName"
                  >{{$t("crt-request.step3.label-1")}}
                  <small style="color: c5c5c5" class="ml-2">
                    {{$t("crt-request.step3.label-desc")}}</small
                  ></label
                >
                <input 
                  spellcheck="true"
                  v-model.trim="item.name"
                  @keyup="checkName()"
                  id="productName"
                  :placeholder="$t('crt-request.step3.ph1')"
                  type="text"
                  class="form-control mb-30"
                />

<!-- ////////////////////////////New Requests Design////////////////// 

                  <b-row rows="12" style="display:flex; justify-content:space-between">

                    <b-col cols="4">

                  <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Width:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                 </b-col>

                  <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Height:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>
                
                </b-col>

                <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Lenght:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                </b-col>

                   </b-row>


                  <b-row style="display:flex; justify-content:space-between">

                    <b-col cols="4">

                  <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Thichness:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                 </b-col>

                  <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Color:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>
                
                </b-col>

                <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px;"
                  >Use of Area:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  placeholder="Fill if it's applicable."
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                </b-col>

                   </b-row>

               


             

      

                   <!-- <b-col cols="6">

                 <label for="productName" style="margin-top:30px; width:50%"
                  >{{$t("crt-request.step3.label-1")}}
                 
                  ></label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  :placeholder="$t('crt-request.step3.ph1')"
                  type="text"
                  class="form-control"
                  style="width:90%"
                />

                  </b-col>

             

//////////////////////////////New Requests Design//////////////////  -->


                <div v-if="validationContext.errors[0]" class="text-danger">
                  {{ validationContext.errors[0] }}
                </div>
                <div v-else-if="nexists" class="text-danger">
                  Request title already exists
                </div>
              </div>

            

           
              
            </validation-provider>
          </div>
          <validation-provider
            name="Product Definition"
            :rules="{ itemDescription: true }"
            v-slot="validationContext"
          >
            <div
              class="form-group mt-4"
              style="margin-bottom: 100px"
              :class="{
                hasError:
                  (
                      !getValidationState(validationContext) && validationContext.errors[0]
                  )
              }"
            >
              <label for="productDesc"> {{$t("crt-request.step3.label-2")}}</label>
              <textarea spellcheck="true" 
                :placeholder="$t('crt-request.step3.ph2')"
                id="productDesc"
                :cols="$t('crt-request.step3.ph2')"
                v-model.trim="item.desc"
                class="form-control"
                style="min-height:160px"
              ></textarea>
               <div class="counter">
              <div v-if="validationContext.errors[0]" class="text-danger">
                {{ validationContext.errors[0] }}
              </div>
              <div class="number-back-counter">
                  {{ counter }} / 1200
              </div>
              </div>
            </div>
          </validation-provider>
        </form>
      </validation-observer>
      <div class="box-info mt-4">
        <div class="box-info-item mb-0">
          <div class="icon">
            <v-icon :icon="['fac', 'lamb']" />
          </div>
          <span
            > {{$t("crt-request.step3.info-p")}}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loading from "@/components/global/loading/index";
import CHECK_NAME from '@/graphql/me/checkInquiry.graphql';
import _ from 'lodash';
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    loading,
  },
  data() {
    return {
      industires: [],
      selectedIndustry: null,
      loading: true,
      item: {},
      nexists: false
    };
  },
  computed: {
      counter() {
          return _.size(this.item.desc);
      }
  },
  watch: {
    item: {
      handler: async function (v) {
        let result = await this.$refs.step3.validate();
        if (!result) {
            this.$emit('validateForm', {});
            return;
        }

        if (this.nexists === true) {
            this.$emit('validateForm', {});
            return;
        }

        this.$emit('validateForm', this.item);
      },
      deep: true,
    },
  },
  methods: {
      async checkName() {
          let selector = await this.$apollo.query(CHECK_NAME, { name: this.item.name || ' ok ' });
          this.nexists = selector('**.check');
      }
  },
  created() {
    this.item = this.detailData;
    this.$emit("validateForm", this.item);
    this.$nextTick(() => this.$refs.step3.reset());
  },
};
</script>
