<template>
  <div>
    <loading :loading="loading" />
    <form v-if="!loading" action="#" class="">
      <div class="row">

        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-1")}}</label>
            <multi-select
              track-by="name"
              label="name"
              :placeholder="$t('crt-quote.step5.ph1')"
              :clearOnSelect="false"
              :closeOnSelect="true"
              :allowEmpty="false"
              :showLabels="false"
              v-model="form.selectUnit"
              :options="units"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title text-capitalize">{{
                    props.option.name
                  }}</span>
                </div>
              </template>
            </multi-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-2")}}</label>
            <multi-select
              track-by="name"
              label="name"
              @select="selectCurrency"
              :placeholder="$t('crt-quote.step5.ph-2')"
              :clearOnSelect="false"
              :closeOnSelect="true"
              :allowEmpty="false"
              :showLabels="false"
              v-model="form.selectCurrency"
              :options="currencies"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multi-select>
          </div>
        </div>

        </div>

<div class="row">

        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-3")}}</label>
            <currency-input
              :currency="inputCurrency"
              v-model="form.minPrice"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-4")}}</label>
            <currency-input
              :currency="inputCurrency"
              v-model="form.maxPrice"
              class="form-control"
            />
          </div>
        </div>

      </div>


      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-5")}}</label>
            <input spellcheck="true"
              v-model="form.capacity"
              :placeholder="$t('crt-quote.step5.ph5')"
              type="number"
              class="form-control"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="">{{$t("crt-quote.step5.label-6")}}</label>
            <input spellcheck="true"
              v-model="form.moq"
              :placeholder="$t('crt-quote.step5.ph6')"
              type="number"
              class="form-control"
            />
          </div>
        </div>

      </div>
    </form>
    <div class="box-info mt-4">
      <div class="box-info-item mb-0">
        <div class="icon">
          <v-icon :icon="['fac', 'lamb']" />
        </div>
        <span
          >{{$t("crt-quote.step5.info-p")}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import loading from "@/components/global/loading/index";

export default {
  components: {
    loading,
  },
  props: {
    infoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      form: {
        capacity: null,
        selectCurrency: null,
        minPrice: null,
        maxPrice: null,
        selectUnit: null,
        moq: null,
      },
      currencies: [],
      units: [],
      inputCurrency: null,
    };
  },
  watch: {
    form: {
      handler(v) {
        this.$emit("updateVal", this.form);
        if (
          !v.capacity ||
          !v.selectCurrency ||
          !v.minPrice ||
          !v.maxPrice ||
          !v.selectUnit ||
          !v.moq
        ) {
          return this.$emit("disable", true);
        }
        if (v.maxPrice) {
          if (v.minPrice > v.maxPrice) {
            this.$emit("disable", true);
            return this.$bvToast.toast(this.$t("Toaster.max-price-ex"),
              {
                title: this.$t("Toaster.danger-title"),
                variant: "danger",
                autoHideDelay: 1350,
              }
            );
          }
        }
        return this.$emit("disable", false);
      },
      deep: true,
    },
  },
  methods: {
    selectCurrency(selectedOption) {
      this.inputCurrency = selectedOption.code;
    },
    async getCurrencies() {
      try {
        let response = await this.$store.dispatch("inquiries/getCurrencies", {
          vm: this,
        });
        this.currencies = response;
      } catch (e) {
        console.log(e);
      }
    },
    async getUnits() {
      try {
        let response = await this.$store.dispatch("inquiries/getUnits", {
          vm: this,
        });
        this.units = response;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    await this.getCurrencies();
    await this.getUnits();
    this.form = this.infoData;
    this.loading = false;
  },
};
</script>
